import { required } from 'vuelidate/lib/validators'
import { guidValidator } from '@/components/validators'

export default {
  provide: function() {
    return {
      $vv: this.$v
    }
  },
  validations: {
    detailGroup: ['entity.assetId', 'entity.invoiceDate'],
    entity: {
      assetId: {
        required,
        validGuid: guidValidator
      },
      invoiceDate: {
        required
      },
      items: {
        $each: {
          invoiceItemDesc: {
            required
          }
        }
      }
    },
    filter: {
      dateFrom: {
        validDate: true // aussieDateValidator
      },
      dateTo: {
        validDate: true // aussieDateValidator
      }
    }
  }
}
